import { BaseWordMark } from 'apps/quests/src/components/BaseWordMark/BaseWordMark';
import { ConnectWalletButton } from 'apps/quests/src/components/ConnectWalletButton/ConnectWalletButton';
import { HStack } from '@cbhq/cds-web/layout/HStack';

// TODO: Update button links
export function Nav() {
  return (
    <HStack
      justifyContent="space-between"
      spacingHorizontal={4}
      spacingVertical={2}
      position="absolute"
      width="100%"
    >
      <BaseWordMark />
      <ConnectWalletButton />
    </HStack>
  );
}
