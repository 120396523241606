/* eslint-disable no-console */
// We will log a lot on this file

// Put all process.env.XXX usages on this file,
// Make sure to add a console error
// Make sure to use isClient for clientside/serverside logging
// Make sure to not log sensitive information
// Populate all of our .env.XXX files accordingly
// If a value is not in our .env files then save it to your local zshrc/bashrc for development
// or provide it on codeflow on deployed environments

// eslint-disable-next-line @cbhq/ts-no-enum
export enum NodeEnv {
  production = 'production',
  development = 'development',
  test = 'test',
}

// eslint-disable-next-line @cbhq/ts-no-enum
export enum ReleaseStage {
  production = 'production',
  development = 'development',
  test = 'test',
}

export const isClient = typeof window !== 'undefined';

// Node Environments
const nodeEnv = process.env.NODE_ENV as NodeEnv;
const isDevelopmentNodeEnv = nodeEnv === NodeEnv.development;
const isTestNodeEnv = nodeEnv === NodeEnv.test;

// Release Stages
export const releaseStage = process.env.NEXT_PUBLIC_RELEASE_STAGE as ReleaseStage;
export const isProductionRelease = releaseStage === ReleaseStage.production;
export const isDevelopmentRelease = releaseStage === ReleaseStage.development;
export const isTestRelease = releaseStage === ReleaseStage.test;

// Environments
export const isLocalDevelopment = isDevelopmentNodeEnv;
export const isTestEnvironment = isTestNodeEnv || isTestRelease;

// Environment Variables
export const AMPLITUDE_KEY = process.env.NEXT_PUBLIC_AMPLITUDE_KEY ?? '';
export const BUGSNAG_API_KEY = process.env.NEXT_PUBLIC_BUGSNAG_API_KEY ?? '';
export const RELEASE_STAGE = process.env.NEXT_PUBLIC_RELEASE_STAGE ?? '';
export const COINBASE_WALLET_API_URL = process.env.NEXT_PUBLIC_COINBASE_WALLET_API;
export const RECAPTCHA_SITE_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY ?? '';
export const BASE_PATH = process.env.NEXT_PUBLIC_BASE_PATH;
export const SITE_URL = process.env.NEXT_PUBLIC_SITE;
export const APP_NAME = process.env.NEXT_PUBLIC_APP_NAME ?? 'Base Quests';

// Error Logging
if (Object.values(ReleaseStage).includes(releaseStage) && !BUGSNAG_API_KEY) {
  console.error(`NEXT_PUBLIC_BUGSNAG_API_KEY is invalid: "${BUGSNAG_API_KEY}"`);
}
