import { createContext, ReactNode, useMemo, useState } from 'react';

export const ConnectWalletContext = createContext<{
  isConnectClick: boolean;
  setIsConnectClick: (isConnectClick: boolean) => void;
}>({
  isConnectClick: false,
  setIsConnectClick: () => {},
});

type ConnectWalletProviderProps = { children: ReactNode };
export function ConnectWalletProvider({ children }: ConnectWalletProviderProps) {
  const [isConnectClick, setIsConnectClick] = useState<boolean>(false);

  const values = useMemo(
    () => ({
      isConnectClick,
      setIsConnectClick,
    }),
    [isConnectClick],
  );

  return <ConnectWalletContext.Provider value={values}>{children}</ConnectWalletContext.Provider>;
}
