// eslint-disable-next-line @cbhq/react-prefer-named-module-import
import React, { Fragment, ReactNode, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { BugsnagErrorBoundary } from '@bugsnag/plugin-react';
import { BaseGoerli } from '@thirdweb-dev/chains';
import {
  coinbaseWallet,
  metamaskWallet,
  ThirdwebProvider,
  walletConnect,
} from '@thirdweb-dev/react';
import { Error500 } from 'apps/quests/src/components/error/Error500';
import { ConnectWalletProvider } from 'apps/quests/src/context/ConnectWalletProvider';
import { Bugsnag, bugsnagStart } from 'apps/quests/src/init/bugsnag';
import { useRouter } from 'next/router';
import { PortalProvider } from '@cbhq/cds-web/overlays/PortalProvider';
import { DevicePreferencesProvider } from '@cbhq/cds-web/system/DevicePreferencesProvider';
import { FeatureFlagProvider } from '@cbhq/cds-web/system/FeatureFlagProvider';
import { ThemeProvider } from '@cbhq/cds-web/system/ThemeProvider';
import { convertToCBSupported, DEFAULT_LOCALE } from '@cbhq/intl';
import { polyfillIntl } from '@cbhq/intl/polyfills';

type Props = { children: ReactNode };

bugsnagStart();

const ErrorBoundary =
  (Bugsnag.getPlugin('react')?.createErrorBoundary(React) as BugsnagErrorBoundary) || Fragment;

const supportedWallets = [coinbaseWallet(), metamaskWallet(), walletConnect()];

export function AppProviders({ children }: Props) {
  const { locale = DEFAULT_LOCALE } = useRouter();

  // Polyfill on mount
  useEffect(() => {
    void polyfillIntl(convertToCBSupported(locale));
  }, [locale]);

  return (
    <ErrorBoundary FallbackComponent={Error500}>
      <FeatureFlagProvider>
        <DevicePreferencesProvider>
          <ThemeProvider spectrum="dark">
            <IntlProvider locale={locale} defaultLocale={DEFAULT_LOCALE}>
              <ThirdwebProvider
                // eslint-disable-next-line react-perf/jsx-no-new-array-as-prop
                supportedChains={[BaseGoerli]}
                activeChain={BaseGoerli}
                supportedWallets={supportedWallets}
              >
                <PortalProvider>
                  <ConnectWalletProvider>{children}</ConnectWalletProvider>
                </PortalProvider>
              </ThirdwebProvider>
            </IntlProvider>
          </ThemeProvider>
        </DevicePreferencesProvider>
      </FeatureFlagProvider>
    </ErrorBoundary>
  );
}
