import { ReactNode } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Footer } from 'apps/quests/src/components/Footer/Footer';
import { Nav } from 'apps/quests/src/components/Nav';
import Head from 'next/head';
import { VStack } from '@cbhq/cds-web/layout/VStack';

const i18nKey = 'QuestsLayout';

const messages = defineMessages({
  metaTitle: {
    id: `${i18nKey}.metaTitle`,
    description: 'Title for meta tag',
    defaultMessage: 'Base | Quests',
  },
});

type Props = { children: ReactNode };

export function Layout({ children }: Props) {
  const { formatMessage } = useIntl();
  return (
    <>
      <Head>
        <title>{formatMessage(messages.metaTitle)}</title>
        <link rel="icon" href="/favicon.svg" sizes="any" type="image/svg+xml" />
      </Head>
      <VStack minHeight="calc(100vh - 200px)" width="100%" background="background">
        <VStack height="100%" width="100%" flexGrow={1}>
          <Nav />
          {children}
        </VStack>
      </VStack>
      <Footer />
    </>
  );
}
