// eslint-disable-next-line @cbhq/react-prefer-named-module-import
import React from 'react';
import Bugsnag, { BrowserConfig, Event } from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import {
  BUGSNAG_API_KEY,
  isClient,
  isLocalDevelopment,
  isProductionRelease,
  isTestEnvironment,
  RELEASE_STAGE,
  ReleaseStage,
  releaseStage,
} from '../config/env';

const enabledReleaseStages = [ReleaseStage.production, ReleaseStage.development];

const isBugsnagEnabled = Boolean(BUGSNAG_API_KEY) && enabledReleaseStages.includes(releaseStage);

const config: BrowserConfig = {
  apiKey: BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact(React)],
  endpoints: {
    notify: 'https://exceptions.coinbase.com',
    sessions: 'https://sessions.coinbase.com',
  },
  collectUserIp: false,
  releaseStage: RELEASE_STAGE,
};

type LogErrorMetadata = {
  name: string;
  data: Record<string, unknown> | Error;
}[];

export type ErrorContext = 'api_request' | 'analytics_sdk' | 'unknown';

export type LogErrorData = {
  // what was the user doing when the error occurred?
  context?: ErrorContext;
  // how severe is the error? defaults to 'warning' for handled errors, and 'error' for unhandled errors
  severity?: Event['severity'];
  // use metadata to create custom tabs on the error page
  metadata?: LogErrorMetadata;
};

function reportError(error: string | Error, data?: LogErrorData) {
  if (!isBugsnagEnabled || !data) {
    return;
  }

  Bugsnag.notify(error, (event: Event) => {
    const { context, severity, metadata } = data;

    // Log errors on the server side, regardless of the environment.
    // Do not log client errors on the production environment.
    if (!isClient || (isClient && !isProductionRelease)) {
      // eslint-disable-next-line
      console.error("[Bugsnag'd error]", error);
    }

    if (metadata) {
      metadata.map((tab) => event.addMetadata(tab.name, tab.data));
    }

    // eslint-disable-next-line no-param-reassign
    event.context = context;

    // eslint-disable-next-line no-param-reassign
    event.severity = severity ?? 'error';
  });
}

function bugsnagStart() {
  // Switch off logging during local development or unit/e2e tests
  if (isLocalDevelopment || isTestEnvironment) {
    Object.assign(config, { logger: null });
  }

  if (isBugsnagEnabled) {
    Bugsnag.start(config);
  }
}

export { Bugsnag, bugsnagStart, reportError };
