import { AppProviders } from 'apps/quests/pages/_app/AppProviders';
import { Layout } from 'apps/quests/src/components/Layout';
import { RECAPTCHA_SITE_KEY } from 'apps/quests/src/config/env';
import { AppProps } from 'next/app';
import Script from 'next/script';

function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Script src={`https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`} />
      <AppProviders>
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </AppProviders>
    </>
  );
}

export default App;
