import { memo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import ErrorImg from 'apps/quests/public/images/error.png';
import { Button } from '@cbhq/cds-web/buttons/Button';
import { Box, VStack } from '@cbhq/cds-web/layout';
import { RemoteImage } from '@cbhq/cds-web/media/RemoteImage';
import { ThemeProvider } from '@cbhq/cds-web/system/ThemeProvider';
import { TextBody, TextTitle3 } from '@cbhq/cds-web/typography';

const i18nKey = 'Error500';

const messages = defineMessages({
  title: {
    id: `${i18nKey}.title`,
    defaultMessage: "Base Quest isn't available right now",
    description: '500 page title',
  },
  subtitle: {
    id: `${i18nKey}.subtitle`,
    defaultMessage: "We're working on getting them back up and running. Please check back later.",
    description: '500 page subtitle',
  },
  button: {
    id: `${i18nKey}.button`,
    description: 'Button text',
    defaultMessage: 'BACK TO BASE',
  },
});

export const Error500 = memo(function Error500() {
  const { formatMessage } = useIntl();

  return (
    <VStack alignItems="center">
      <VStack alignItems="center" justifyContent="center">
        <RemoteImage source={ErrorImg.src} resizeMode="contain" width={270} height={270} />
      </VStack>

      <TextTitle3 as="span" spacingBottom={1} spacingTop={5}>
        {formatMessage(messages.title)}
      </TextTitle3>

      <TextBody as="span" color="foregroundMuted" align="center">
        {formatMessage(messages.subtitle)}
      </TextBody>

      <Box spacingTop={4}>
        <ThemeProvider spectrum="light">
          <Button variant="secondary" to="https://base.org/">
            {formatMessage(messages.button)}
          </Button>
        </ThemeProvider>
      </Box>
    </VStack>
  );
});
