import { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { BaseWordMark } from 'apps/quests/src/components/BaseWordMark/BaseWordMark';
import getConfig from 'next/config';
import type { ResponsivePropsDevices, ResponsiveStyles } from '@cbhq/cds-common/types';
import { useBreakpoints } from '@cbhq/cds-web/hooks/useBreakpoints';
import { useHasMounted } from '@cbhq/cds-web/hooks/useHasMounted';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { Link, TextBody, TextLegal } from '@cbhq/cds-web/typography';

const i18nKey = 'QuestsFooter';
const messages = defineMessages({
  termsOfService: {
    id: `${i18nKey}.termsOfService`,
    description: 'Terms of service link ',
    defaultMessage: 'Terms of Service',
  },
});

const { publicRuntimeConfig } = getConfig();

export function Footer() {
  const { formatMessage } = useIntl();

  const { isPhone } = useBreakpoints();
  const hasMounted = useHasMounted();

  const Stack = hasMounted && isPhone ? VStack : HStack;

  const responsiveConfig: Partial<Record<ResponsivePropsDevices, ResponsiveStyles>> = useMemo(
    () => ({
      phone: {
        spacingTop: 4,
      },
    }),
    [],
  );

  return (
    <VStack
      background="backgroundAlternate"
      justifyContent="space-between"
      alignContent="center"
      spacingHorizontal={4}
      spacingVertical={4}
      gap={6}
      height="100%"
    >
      <Stack justifyContent="space-between">
        <Box alignItems="flex-start">
          <BaseWordMark />
        </Box>
        <Stack gap={4} responsiveConfig={responsiveConfig}>
          <Link to="https://base.org/about" openInNewWindow>
            <TextBody mono as="span">
              About
            </TextBody>
          </Link>
          <Link to="https://docs.base.org/" openInNewWindow>
            <TextBody mono as="span">
              Docs
            </TextBody>
          </Link>
          <Link to="https://bridge.base.org/" openInNewWindow>
            <TextBody mono as="span">
              Bridge
            </TextBody>
          </Link>
          <Link to="https://base.mirror.xyz/" openInNewWindow>
            <TextBody mono as="span">
              Blog
            </TextBody>
          </Link>
          <Link to="https://base.org/discord" openInNewWindow>
            <svg
              width={24}
              height={20}
              viewBox="0 0 24 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.3303 2.50578C18.7535 1.78387 17.0888 1.27172 15.3789 0.982422C15.1449 1.40071 14.9332 1.83107 14.7446 2.27171C12.9232 1.99724 11.071 1.99724 9.24961 2.27171C9.06095 1.83112 8.84924 1.40076 8.61535 0.982422C6.90433 1.27417 5.2386 1.78753 3.66019 2.50955C0.526644 7.14569 -0.322811 11.6667 0.101917 16.1235C1.937 17.4793 3.99098 18.5105 6.17458 19.1721C6.66626 18.5108 7.10134 17.8093 7.47519 17.0749C6.76511 16.8097 6.07975 16.4825 5.42706 16.0971C5.59884 15.9725 5.76684 15.8441 5.92918 15.7195C7.82837 16.6127 9.90124 17.0758 12 17.0758C14.0987 17.0758 16.1715 16.6127 18.0707 15.7195C18.235 15.8536 18.403 15.9819 18.5729 16.0971C17.9189 16.4831 17.2323 16.8109 16.5209 17.0768C16.8943 17.8108 17.3294 18.5118 17.8216 19.1721C20.007 18.5131 22.0626 17.4825 23.898 16.1254C24.3963 10.9569 23.0467 6.47746 20.3303 2.50578ZM8.01318 13.3826C6.82961 13.3826 5.85179 12.3085 5.85179 10.9871C5.85179 9.66575 6.79563 8.58222 8.0094 8.58222C9.22318 8.58222 10.1934 9.66575 10.1727 10.9871C10.1519 12.3085 9.21941 13.3826 8.01318 13.3826ZM15.9867 13.3826C14.8013 13.3826 13.8272 12.3085 13.8272 10.9871C13.8272 9.66575 14.7711 8.58222 15.9867 8.58222C17.2024 8.58222 18.1651 9.66575 18.1444 10.9871C18.1236 12.3085 17.193 13.3826 15.9867 13.3826Z"
                fill="white"
              />
            </svg>
          </Link>
          <Link to="https://twitter.com/buildonbase" openInNewWindow>
            <svg
              width={24}
              height={20}
              viewBox="0 0 24 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.543 5.18103C21.5576 5.39261 21.5576 5.60419 21.5576 5.81771C21.5576 12.324 16.6045 19.8277 7.54759 19.8277V19.8238C4.87215 19.8277 2.25229 19.0613 0 17.6164C0.389031 17.6632 0.780012 17.6866 1.17197 17.6875C3.38915 17.6895 5.54296 16.9456 7.28726 15.5757C5.18026 15.5357 3.3326 14.1619 2.68714 12.1563C3.42523 12.2986 4.18574 12.2694 4.91018 12.0715C2.61304 11.6074 0.96039 9.58907 0.96039 7.24514V7.18274C1.64485 7.56397 2.41121 7.77554 3.19513 7.79894C1.03157 6.353 0.364656 3.47475 1.67118 1.22442C4.17112 4.30059 7.8596 6.17067 11.8191 6.3686C11.4223 4.65842 11.9644 2.86634 13.2436 1.66415C15.2268 -0.200081 18.3459 -0.104529 20.2101 1.87768C21.3129 1.66025 22.3698 1.25562 23.337 0.682308C22.9694 1.8221 22.2001 2.79029 21.1725 3.40553C22.1484 3.29047 23.102 3.02917 24 2.63039C23.3389 3.621 22.5063 4.48389 21.543 5.18103Z"
                fill="white"
              />
            </svg>
          </Link>
          <Link to="https://github.com/base-org" openInNewWindow>
            <svg
              width={24}
              height={20}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.0099 0.277344C5.36875 0.277344 0 5.68567 0 12.3765C0 17.7249 3.43994 22.2521 8.21205 23.8545C8.80869 23.9749 9.02724 23.5941 9.02724 23.2738C9.02724 22.9933 9.00757 22.0318 9.00757 21.0301C5.6667 21.7514 4.97099 19.5878 4.97099 19.5878C4.43409 18.1855 3.63858 17.8252 3.63858 17.8252C2.54511 17.084 3.71823 17.084 3.71823 17.084C4.93117 17.1641 5.56763 18.3259 5.56763 18.3259C6.64118 20.1687 8.37111 19.648 9.06706 19.3274C9.16638 18.5462 9.48473 18.0054 9.82275 17.7049C7.15817 17.4244 4.35469 16.3829 4.35469 11.7354C4.35469 10.4133 4.8316 9.33162 5.58729 8.49038C5.46807 8.18997 5.0504 6.94778 5.70677 5.28521C5.70677 5.28521 6.72083 4.96464 9.00732 6.52716C9.98625 6.26231 10.9958 6.12758 12.0099 6.12645C13.024 6.12645 14.0577 6.26682 15.0123 6.52716C17.299 4.96464 18.3131 5.28521 18.3131 5.28521C18.9695 6.94778 18.5515 8.18997 18.4323 8.49038C19.2079 9.33162 19.6652 10.4133 19.6652 11.7354C19.6652 16.3829 16.8617 17.4043 14.1772 17.7049C14.6148 18.0855 14.9924 18.8065 14.9924 19.9484C14.9924 21.5709 14.9727 22.8731 14.9727 23.2736C14.9727 23.5941 15.1915 23.9749 15.7879 23.8547C20.56 22.2519 23.9999 17.7249 23.9999 12.3765C24.0196 5.68567 18.6312 0.277344 12.0099 0.277344Z"
                fill="white"
              />
            </svg>
          </Link>
        </Stack>
      </Stack>
      <VStack gap={1}>
        <Link
          openInNewWindow
          underline
          color="foreground"
          to={`${publicRuntimeConfig.marketingURL}/terms-of-service`}
        >
          <TextLegal as="span">{formatMessage(messages.termsOfService)}</TextLegal>
        </Link>
        <TextLegal as="span">© 2023 Coinbase</TextLegal>
      </VStack>
    </VStack>
  );
}
